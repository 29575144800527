import React from "react";
import { graphql } from 'gatsby'
// import Img from "gatsby-image";
import AOS from 'aos';

import PrimaryLayout from '../layouts/PrimaryLayout';
import SEO from '../layouts/SEO';

export default function Tools({data}) {
  if (typeof window !== 'undefined') {
    AOS.init({ duration : 800});
  }
  return (
    <PrimaryLayout>
      <SEO 
        title={data.wordpressPage.acf.seo_title}
        description={data.wordpressPage.acf.seo_description}
        keywords={data.wordpressPage.acf.seo_keywords}
      />
      <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }} />
    </PrimaryLayout>
  )
}


export const query = graphql`
{
  wordpressPage(slug: {eq: "our-tools"}) {
    content
    featured_media {
      source_url
    }
    title
    acf{
      seo_title
      seo_keywords
      seo_description
    }
  }
}

`